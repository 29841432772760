  
  /* width */
  ::-webkit-scrollbar {
    width: 7px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: green; 
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

.body
{
  overflow-x: hidden;
}
.service-desk
{
  position: fixed;
  right: 20px;
  bottom: 20px;
}
.service-desk p
{
  color: #fff;
  background: green;
  padding: .5em 1em;
  //margin-top: .5em;
  cursor: pointer;
  //border: 1px solid green;
  border-radius: 5px;
}
.service-desk p:hover
{
  color: #fff;
  background: #0b450e;
  //border: 1px solid #0b450e;
}
.service-desk-mobile
{
  display: none;
}
@media screen and (max-width:800px)
{
  .service-desk-mobile
  {
    display: block;
    position: fixed;
    right: 20px;
    bottom: 20px;
  }
  .service-desk-mobile p
  {
    color: #fff;
    background: green;
    padding: .5em 1em;
    //margin-top: .5em;
    cursor: pointer;
    //border: 1px solid green;
    border-radius: 5px;
  }
  .service-desk-mobile p:hover
  {
    color: #fff;
    background: #0b450e;
    //border: 1px solid #0b450e;
  }
  .service-desk
  {
    display: none;
  }
}
.service-form-bg
{
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.5);
}
.service-form
{
  background: #efefef;
  width: 60%;
  margin: 1em auto;
  padding: 2em;
  border: 1px solid grey;
  border-radius: 5px;
}
.service-form-mobile
{
  background: #efefef;
  width: 100%;
  margin: 1em auto;
  padding: 2em;
  border: 1px solid grey;
  border-radius: 5px;
}
.service-desk-mobile a:hover
{
  text-decoration: none;
}
.close-service-form
{
  float: right;
  cursor: pointer;
  margin-bottom: .5em;
}
.close-service-form:hover
{
  color: green;
}
.headerNav .icon-top
{
  font-size: .1em;
}
.logoCover
{
  //position: fixed;
  left: 100px;
  top: 250px;
  z-index: 1;
  margin-top: 6em;
  margin-left: 2em;
  animation: mymove 5s infinite;
}
.logoPages
{
  text-align: center;
  animation: mymove 5s infinite;
  max-width: 100%;
}
.logoPagesCover
{
  margin-top: 2em;
  margin-bottom: 6em;
  text-align: center;
}
@keyframes mymove {
  50% {box-shadow: 5px 5px 30px green;}
}
.nav
{
  //display: none;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, .7);
  color: #fff;
  position: fixed;
  top: -140%;
  left: 0px;
  z-index: 200;
  transition: top .5s;
  border-bottom: 1px solid #333;
}
.nav span
{
  cursor: pointer;
  font-family: tahoma;
  //font-weight: bold;
  font-size: 3em;
  position: absolute;
  top: 20px;
  right: 70px;
}
.nav ul
{
  list-style-type: none;
  margin-top: 2em;
}
.nav ul li
{
  //display: inline-block;
  //font-size: 2em;
  padding: 1em;
  font-family: tahoma;
  font-weight: bold;
  border-radius: 5px;
  //margin: 1em;
  //background: green;
  cursor: pointer;
}
.nav ul li:hover
{
  text-shadow: 2px 2px 8px green;
}
.nav ul li a:link
{
  text-decoration: none;
  color: #fff;
}
.nav ul li a:visited
{
  text-decoration: none;
  color: #fff;
}
.header
{
  color: #fff;
  width: 100%;
  height: 100vh;
  background: url('./imgs/—Pngtree—simulation creative texture integrated circuit_1175271.png');
  background-attachment: fixed;
}
.headerTopLayer
{
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, .7);
}
.headerTopLayer h1
{
  //position: absolute;
  width: 50%;
  //margin: 0% auto;
  margin-left: 12.3em;
  margin-top: -4em;
  //padding-top: 2em;
  font-size: 3.5em;
  font-family: tahoma !important;
}
.menuBg
{
  transition: top 1s;
  position: absolute;
  top: 30px;
  right: 60px;
  cursor: pointer;
}
.menuBgTop 
{
  position: relative;
  width: 50px;
  height: 5px;
  background: #fff;
  //border-radius: 5px;
  margin-bottom: 10px;
  transition: left 1s;
}
.menuBgCenter
{
  position: relative;
  width: 50px;
  height: 5px;
  background: #fff;
  //border-radius: 5px;
  margin-bottom: 10px;
  transition: left 1.5s;
}
.menuBgBottom
{
  position: relative;
  width: 50px;
  height: 5px;
  background: #fff;
  //border-radius: 5px;
  margin-bottom: 10px;
  transition: left 2s;
}

.bottomArrow
{
  //position: absolute;
  left: 50%;
  top: 600px;
  //z-index: 10000;
  width: 3px;
  height: 800x;
  cursor: pointer;
  background: #fff;
}
.downArrowHero
{
  font-size: 2em;
  cursor: pointer;
  position: absolute;
  left: 50%;
  top: 90vh;
  transition: left 1s, top 2s, transform 2s;
  animation: jumpInfinite 1.5s infinite;
}
@keyframes jumpInfinite {
  0% {
    margin-top: 0;
  }
  50% {
    margin-top: 20px;
  }
  100% {
    margin-top: 0;
  }
}
.servicCard
{
  display: 'flex';
  flex-direction: column;
  height: 80vh;
  position: relative;
  z-index: 2;
  cursor: pointer;
  padding: 2em;
  border: 1px solid #3a4c3b;
  border-radius: 16px;
  margin-bottom: 1em;
  background: rgba(255, 255, 255, 0.1);
}

.servicCard .imageWrapper img
{
  max-width: 100%;
}


.secOneHalf
{
  height: 100vh;
  padding: 4em;
  background-image: linear-gradient(#011001, #000, #011001);
  color: #fff;
}
.secOneHalf a:link
{
  color: #fff;
  text-decoration: none;
}
.secOneHalf a:visited
{
  color: #fff;
  text-decoration: none;
}
.right-arrow
{
  font-size: 2em;
  float: right;
  color: green;
}
.left-arrow
{
  font-size: 2em;
  float: left;
  color: green;
  margin-top: auto;
}
.down-arrow-link:hover
{
  color: green;
}
.down-arrow-link:visited
{
  color: #fff;
}
.down-arrow-link:link
{
  color: #fff;
}
.card-img
{
  max-width: 100%;
  height: 70vh;
}
.sec2
{
  padding: 4em;
  background: #011001;
  padding-bottom: 2em;
}
.sec2 h1
{
  margin-bottom: 2em;
}
.topFooter
{
  padding: 6em 4em;
}
.topFooter h1
{
  margin-bottom: 2em;
}
.footer
{
  padding: .5em;
  background: #011001;
  color: #fff;
}
.imageWrapper {
  max-width: 390px; /* Desired width for regular PC screens */
  width: 100%;
}

.imageWrapper img {
  height: auto;
}

@media screen and (max-width:800px)
{
    .logoCover
    {
      width: 90%;
      margin: 7em auto;
      margin-left: 1em;
    }
    .headerTopLayer h1
    {
      display: none;
    }
    .header
    {
      height: 100vh;
    }
    .sec1
    {
      padding: 1em;
      display: none;
    }
    .sec2
    {
      padding: 1em;
    }
    .topFooter
    {
      padding: 3em 1em;
    }
    .secOneHalf
    {
      height: 50vh;
      padding: 2em;
    }
    .card-img
    {
      max-width: 100%;
      height: 30vh;
    }
    .servicCard p
    {
      display: none;
    }
    .servicCard
    {
      display: 'flex';
      flex-direction: column;
      height: auto;
      position: relative;
      z-index: 2;
      cursor: pointer;
      padding: 1em;
      border: 1px solid #3a4c3b;
      border-radius: 16px;
      margin-bottom: 5em;
      background: rgba(255, 255, 255, 0.1);
    }
    .right-arrow
    {
      display: none;
    }
    .left-arrow
    {
      display: none;
    }
    .menuBg
    {
      top: 20px;
      right: 10px;
    }
    .nav
    {
      left: -.5%;
    }
    .nav span
    {
      cursor: pointer;
      font-family: tahoma;
      //font-weight: bold;
      font-size: 3em;
      position: absolute;
      top: 20px;
      right: 20px;
    }
    .downArrowHero
    {
      display: none;
    }
    .service-form
    {
      width: 90%;
    }
}


.about
{
  background: linear-gradient(#011001, #000, #011001);
  padding: 2em;
  color: #fff;
  height: auto;
  width: 100%;
}


.repairs-img-card
{
  max-width: 100%;
}
.repairs-card
{
  margin-bottom: 2em;
  background: #fff;
  padding: 2em;
  border-radius: 5px;
  border: 1px solid #0000;
  color: #333;
  cursor: pointer;
}
.repairs-card h3
{
  text-align: center;
}
.repairs-card p
{
  display: none;
}
.card-cctv
{
  text-align: center;
  margin-bottom: 2em;
  padding: 2em;
}
.pos-text
{
  padding: 3em;
}
.pos-text3
{
  padding: 3em;
  margin-top: 7em;
}
.network1
{
  margin-bottom: 4em;
}
.inv-img
{
  padding: 2em;
}
.w-small
{
  width: 30%;
  margin: .2em;
}

.flex-container
{
  display: flex;
}

@media (max-width: 800px) {
  .flex-container {
    flex-direction: column;
  }
}

.icon-software1
{
   background: #50c7c7;
   padding: .5em;
   width: 100%;
   margin: 0px !important;
   font-size: 2em;
   height: 5em;
   text-align: center;
   height: 9em;
   cursor: pointer;
}
.icon-software2
{
   background: #50c758;
   padding: .5em;
   width: 100%;
   margin: 0px !important;
   font-size: 2em;
   height: 5em;
   text-align: center;
   height: 9em;
   cursor: pointer;
}
.icon-software3
{
   background: #c75052;
   padding: .5em;
   width: 100%;
   margin: 0px !important;
   font-size: 2em;
   height: 5em;
   text-align: center;
   height: 9em;
   cursor: pointer;
}
.icon-software4
{
   background: #c750c3;
   padding: .5em;
   width: 100%;
   margin: 0px !important;
   font-size: 2em;
   height: 5em;
   text-align: center;
   height: 9em;
   cursor: pointer;
}
.icon-software5
{
   background: #c7a750;
   padding: .5em;
   width: 100%;
   margin: 0px !important;
   font-size: 2em;
   height: 5em;
   text-align: center;
   height: 9em;
   cursor: pointer;
}
.icon-software6
{
   background: rgba(91, 80, 199, 1);
   padding: .5em;
   width: 100%;
   margin: 0px !important;
   font-size: 2em;
   height: 5em;
   text-align: center;
   height: 9em;
   cursor: pointer;
}
.icon-software-icon
{
  font-size: 4em;
  margin-bottom: .5em;
}
.icon-software-text
{
  //font-size: 2em;
}